import * as React from "react"
import DatePicker from "react-datepicker"
import { FiChevronLeft, FiChevronRight } from "react-icons/fi"
import type { InputProps } from "@chakra-ui/react"
import { HStack, Select } from "@chakra-ui/react"
import { Box, Flex, FormControl, IconButton, Input as CInput, Text } from "@chakra-ui/react"
import dayjs from "dayjs"
import "react-datepicker/dist/react-datepicker.css"

import { useFormContext } from "lib/hooks/useForm"

import { InputError } from "./InputError"
import { InputLabel } from "./InputLabel"

interface DateInputProps extends InputProps {
  name: string
  label?: string
  subLabel?: string
  minDate?: Date
}

export function DateInput({ minDate, ...props }: DateInputProps) {
  const { register, watch, setValue } = useFormContext()
  const value = watch(props.name)

  const handleChange = (date: string) => setValue(props.name, date, { shouldDirty: true })

  React.useEffect(() => {
    register(props.name)
  }, [register, , props.name])

  return (
    <Box pos="relative" w="100%">
      <DatePicker
        dateFormat="dd/MM/yyyy"
        disabled={props.isDisabled}
        dropdownMode="select"
        showPopperArrow={false}
        fixedHeight
        minDate={minDate}
        selected={value ? dayjs(value).toDate() : null}
        onChange={(date) => {
          handleChange(date ? dayjs(date as Date).format("YYYY-MM-DD") : "")
        }}
        customInput={<CustomInput {...props} inputName={props.name} />}
        renderCustomHeader={({ date, decreaseMonth, increaseMonth, changeMonth, changeYear }) => (
          <Box>
            <Flex align="center" justify="space-evenly" position="relative">
              <IconButton
                variant="ghost"
                size="sm"
                _hover={{ opacity: 0.5 }}
                aria-label="decrease month"
                color="black"
                icon={<Box boxSize="24px" as={FiChevronLeft} />}
                onClick={decreaseMonth}
              />
              <Text fontSize="sm">
                {date.toLocaleString("en-EN", {
                  month: "long",
                  year: "numeric",
                })}
              </Text>
              <IconButton
                variant="ghost"
                size="sm"
                _hover={{ opacity: 0.5 }}
                aria-label="increase month"
                color="black"
                icon={<Box boxSize="24px" as={FiChevronRight} />}
                onClick={increaseMonth}
              />
            </Flex>
            <HStack px={4}>
              <Select
                variant="outline"
                onChange={(e) => changeMonth(parseInt(e.target.value))}
                size="xs"
                value={dayjs(date).month()}
              >
                {Array.from({ length: 12 }).map((_, i) => (
                  <option key={i} value={i}>
                    {dayjs().month(i).format("MMMM")}
                  </option>
                ))}
              </Select>
              <Select
                onChange={(e) => changeYear(parseInt(e.target.value))}
                size="xs"
                value={dayjs(date).year()}
              >
                {Array.from({ length: 10 }).map((_, i) => (
                  <option key={i} value={dayjs().year() - 5 + i}>
                    {dayjs().year() - 5 + i}
                  </option>
                ))}
              </Select>
            </HStack>
          </Box>
        )}
      />
    </Box>
  )
}

function _CustomInput(
  { label, subLabel, inputName, ...props }: DateInputProps & { inputName: string },
  ref: React.Ref<HTMLInputElement>,
) {
  const {
    formState: { errors },
  } = useFormContext()
  const fieldError = errors?.[inputName]
  return (
    <FormControl isInvalid={!!fieldError} mb={0} isRequired={props.isRequired}>
      <Flex align="center">
        <InputLabel label={label} subLabel={subLabel} name={inputName} />
      </Flex>
      <CInput mb={0} w="100%" ref={ref} {...props} />
      <InputError error={fieldError} />
    </FormControl>
  )
}

const CustomInput = React.forwardRef(_CustomInput)
