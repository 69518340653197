import type { UseToastOptions } from "@chakra-ui/react"
import * as path from "path"

import type { TextToTranslation } from "lib/hooks/useTranslation"

export interface CustomFile extends File {
  path?: string
}

const images = [".png", ".jpg", ".jpeg", ".webp"]
const other = [".pdf"]
export const DEFAULT_ACCEPTED_FILETYPES = {
  all: [...images, ...other],
  images,
  other,
}
export const BANK_STATEMENT_FILETYPES = [".sta", ".940", ".txt", ".mt940", ".csv", ".swi", ".pdf"]

// NOTE: I know this is a bit shit to have to pass in bt and toast hooks here, it's not a true helper method, but don't have the time to improve it right now
export const isValidFile = (
  file: CustomFile,
  bt: (text: TextToTranslation) => string,
  toast: (props: UseToastOptions) => void,
  dropzoneMaxSize?: number,
  acceptAllFiletypes?: boolean,
  customFiletypes?: string[],
) => {
  const ext = file.path ? path.parse(file.path).ext.toLowerCase() : undefined

  // ext check
  if (!ext) {
    toast({
      status: "error",
      description: bt({ en: "Invalid file (no filetype)", nl: "Ongeldig bestand (geen bestandstype)" }),
    })
    return false
  }

  const fileTypeToast = (supported: string) => {
    toast({
      status: "error",
      title: bt({ en: `Rejected file '${file.name}'`, nl: `Geweigerd bestand '${file.name}'` }),
      description: bt({
        en: `Unsupported filetype (${ext}). Supported filetypes: ${supported}`,
        nl: `Niet-ondersteund bestandstype (${ext}). Ondersteunde bestandstypen: ${supported}`,
      }),
      duration: 10000, // show a little longer than normal so you can read it all
    })
  }

  // Filetype check
  if (!acceptAllFiletypes) {
    if (customFiletypes && customFiletypes.length > 0) {
      if (!customFiletypes.includes(ext)) {
        fileTypeToast(customFiletypes.join(", "))
        return false
      }
    } else if (!DEFAULT_ACCEPTED_FILETYPES.all.includes(ext)) {
      fileTypeToast(DEFAULT_ACCEPTED_FILETYPES.all.join(", "))
      return false
    }
  }

  let maxSize = dropzoneMaxSize
  if (!maxSize) {
    if (DEFAULT_ACCEPTED_FILETYPES.images.includes(ext)) {
      maxSize = 1024 * 1024 * 40 // 40MB
    } else {
      maxSize = 1024 * 1024 * 10 // 10MB
    }
  }

  // Filesize check
  if (file.size > maxSize) {
    const description = bt({
      en: `File too large, must be under ${Math.floor(maxSize / 1000000)}MB`,
      nl: `Bestand te groot, moet kleiner zijn dan ${Math.floor(maxSize / 1000000)}MB`,
    })
    toast({ status: "error", title: bt({ en: "Invalid file", nl: "Ongeldig bestand" }), description })
    return false
  }

  return true
}
